import { useEffect, useState } from 'react';
import { ApiService } from '../../services/ApiService';
import { Article } from '../../models/Article';
import { base_url } from '../../config/api';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLayerGroup, faTree } from '@fortawesome/free-solid-svg-icons';
import { Category } from '../../models/Category';
import RelatedArticlesComp from './RelatedArticles';

function SingleArticlePage() {
  let { category, slug } = useParams();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    console.log('**************************************************************');

    // ApiService('get', '/sponsors?pagination[limit]=5&populate=*')
    ApiService('get', '/articles?populate=*&filters[slug][$eqi]=' + slug)
      .then((resp) => {
        const categoryArticles = 'data' in resp ?
          resp.data.map(
            (m: Article) => {
              return m;
            }
          )
          : [];
        setArticles(categoryArticles);
      });
  }, [category, slug])

  return (
    <div className="container pb-5">
      {articles.map((article: Article) =>
        <>
          <div className="row mb-5">

            <div key={'article_' + article.id} className="col-sm-12 mb-4">

              <div className='d-flex flex-column bg-white h-100 shadow-sm'>
                <div className="img-cover h-500">
                  <img
                    alt={article.attributes.Title}
                    height={500}
                    className="ticker-dealer-image w-100"
                    src={base_url + article.attributes.Cover.data.attributes.url} />
                  <div className="categories">
                    <span className="badge">

                    </span>
                  </div>
                </div>
                <h2 className='px-5 pt-5 pb-2 font-bold'>{article.attributes.Title}</h2>
                <div className='px-5 pb-3'>
                  <FontAwesomeIcon icon={faClock} />
                  <span>{article.attributes.publishedAt.toString()}</span>
                </div>
                <div className='px-5 pb-3'>
                  <FontAwesomeIcon icon={faLayerGroup} />
                  <span>{article.attributes.categories.data.map((cat: Category) =>
                    <Link to={"/content/category/" + cat.attributes.slug} className="badge bg-primary ms-2">{cat.attributes.name}</Link>
                  )}</span>
                </div>

                <hr className='my-3' />

                <div className="article-body px-5 pt-3 pb-5" dangerouslySetInnerHTML={{ __html: article.attributes.Body }} ></div>
              </div>
            </div>
          </div>

          <RelatedArticlesComp article={article} />
        </>
      )
      }
    </div >
  );
}

export default SingleArticlePage;
