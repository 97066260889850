import React, { useEffect, useState } from 'react';
import SectionTitle from '../../../components/SectionTitle';
import { base_url } from '../../../config/api';
import { Dealership } from '../../../models/Dealership';
import { ApiService } from '../../../services/ApiService';

function SponsorsComp() {
  const [dealerships, setDealerships] = useState([]);

  useEffect(() => {
    // ApiService('get', '/sponsors?pagination[limit]=5&populate=*')
    ApiService('get', '/sponsors?populate=*&pagination[limit]=4')
      .then((resp) => {
        const deals = 'data' in resp ?
          resp.data.map(
            (m: Dealership) => {
              return m;
            }
          )
          : [];
        setDealerships(deals);
      });
  }, [])

  return (
    <div className="row mb-5">
      <div className="col-sm-12">
        <SectionTitle title={'Our Sponsors'} link='/sponsors' />
        <div className="content-wrapper row">
          {dealerships.map((dealer: Dealership) =>

            <div key={'dealer_' + dealer.id} className="col-sm-3 mb-3">

              <div className='d-flex flex-column bg-white h-100 shadow-sm'>
                <div className="cover-img">
                  <img alt={dealer.attributes.Name} height={180} className="ticker-dealer-image w-100" src={base_url + dealer.attributes.Logo.data.attributes.url} />
                </div>
                <div className='text-center p-3 text-uppercase font-bold'>{dealer.attributes.Name}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SponsorsComp;
