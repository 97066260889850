import { useEffect, useState } from 'react';
import SectionTitle from '../../components/SectionTitle';
import { ApiService } from '../../services/ApiService';
import { Vehicle } from '../../models/Vehicle';
import { base_url } from '../../config/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationPin, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import PaginationComp from '../../components/PaginationComp';
import { Link, useSearchParams } from 'react-router-dom';
import { Pagination, Paginator } from '../../models/Paginator';

function ListingsPage() {
  const [vehicles, setVehicles] = useState([]);
  const [pagination, setPagination] = useState<Pagination>({ pageCount: 0, total: 0, });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const page = searchParams.get('page') ?? '1';
    const pageSize = searchParams.get('pageSize') ?? '9';
    ApiService('get', '/vehicles?populate=*&pagination[pageSize]=' + pageSize + '&pagination[page]=' + page + '&sort[0]=publishedAt%3Aasc&sort[1]=id%3Adesc')
      .then((resp) => {
        const deals = 'data' in resp ?
          resp.data.map(
            (d: Vehicle) => {
              return d;
            }
          )
          : [];
        const meta: Pagination = 'meta' in resp ?
          {
            page: resp.meta.pagination.page,
            pageSize: resp.meta.pagination.pageSize,
            total: resp.meta.pagination.total,
            pageCount: resp.meta.pagination.pageCount
          }
          : { page: 1, pageSize: 9, total: 0, pageCount: 0 };
        setPagination(meta)
        setVehicles(deals);
      });
  }, [searchParams])

  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12">
          <div className="content-wrapper row">
            {vehicles.map((vehicle: Vehicle) =>

              <div key={'vehicle_' + vehicle.id} className="col-md-4 mb-3">

                <Link to={'/listings/' + vehicle.attributes.slug} className='d-flex flex-column bg-white h-100 shadow-sm'>
                  <div className="img-cover h-300 position-relative">
                    <img
                      alt={vehicle.attributes.Name}
                      height={300}
                      className="ticker-dealer-image w-100"
                      src={base_url + vehicle.attributes.Cover?.data?.attributes.url} />

                    <div className='overlay-info'>

                      <div className='d-flex justify-content-between'>
                        <div>
                          <div className="article-title font-bold text-uppercase align-items-center">
                            {vehicle.attributes.Name}
                          </div>
                          <div className="d-flex justify-content-start gap-3">
                            <small> <FontAwesomeIcon icon={faWarehouse} /> {vehicle.attributes.dealership?.data.attributes.Name}</small>
                            <small> <FontAwesomeIcon icon={faLocationPin} /> {vehicle.attributes.dealership?.data.attributes.Location}</small>
                          </div>
                        </div>
                        <div className="price">
                          {vehicle.attributes.Price}
                        </div>
                      </div>
                      <div className="d-flex  justify-content-between vehicle-basics">
                        <div className="col-sm-"><span className='orange'>Make:</span> {vehicle.attributes.Make}</div>
                        <div className="divider"></div>
                        <div className="col-sm-"><span className='orange'>Model:</span> {vehicle.attributes.Model}</div>
                        <div className="divider"></div>
                        <div className="col-sm4"><span className='orange'>Year:</span> {vehicle.attributes.Year}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <PaginationComp total={pagination.total} />
    </div>
  );
}

export default ListingsPage;
