import { faChevronDown, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
// import './App.css';

function MenuBar() {
  const location = useLocation();

  useEffect(() => {

    return () => {
    }
  }, [location])

  return (
    <section className='menubar'>
      <div className='container'>
        <menu className='d-flex justify-content-between'>
          <div className="menu-links d-flex">
            <Link to={'/'} className="home" >
              <FontAwesomeIcon icon={faHome} />
            </Link>
            <NavLink to="/listings" className={({ isActive }) => (isActive ? 'active' : '')}>Listings</NavLink>
            <NavLink to="/vehicle-reviews" className={({ isActive }) => (isActive ? 'active' : '')}>Vehicle Reviews</NavLink>
            <NavLink to="/sponsors" className={({ isActive }) => (isActive ? 'active' : '')}>Sponsors</NavLink>
            <div className="nav-dropdown">
              <div className={location.pathname.startsWith('/content/') ? 'nav-dropdown-link d-flex gap-1 active' : 'nav-dropdown-link d-flex gap-1'} >
                <span>Content</span> <FontAwesomeIcon icon={faChevronDown} />
              </div>
              <div className="nav-dropdown-menu flex-column shadow">
                <NavLink to="/content/category/vehicle-news" className={({ isActive }) => (isActive ? 'active' : '')}>Vehicle News</NavLink>
                <NavLink to="/content/category/vehicle-events" className={({ isActive }) => (isActive ? 'active' : '')}>Vehicle Events</NavLink>
                <NavLink to="/content/category/competition" className={({ isActive }) => (isActive ? 'active' : '')}>Competition</NavLink>
                <NavLink to="/content/category/did-you-know" className={({ isActive }) => (isActive ? 'active' : '')}>Did you know</NavLink>
                <NavLink to="/content/category/road-safety" className={({ isActive }) => (isActive ? 'active' : '')}>Road Safety</NavLink>
                <NavLink to="/content/category/street" className={({ isActive }) => (isActive ? 'active' : '')}>Street</NavLink>
              </div>
            </div>
          </div>
          <div className="search">
            <button className="btn search-btn">Search</button>
          </div>
        </menu>
      </div>
    </section>
  );
}

export default MenuBar;
