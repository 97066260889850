import React from 'react';
import TopBar from './TopBar';
import './Header.css';
import MenuBar from './MenuBar';
import Logo from '../../assets/images/icon.png';
import PlayStore from '../../assets/images/play_store.png';
import AppStore from '../../assets/images/app_store.png';
import BrandText from '../../assets/images/vva_text.png';
import TickerBar from './TickerBar';

function Header() {
  return (
    <header>
      <TopBar />
      <section className='header-content'>
        <div className="container d-flex justify-content-center justify-content-sm-between">
          <div className='logo-image'>
            <img alt='VVA Logo' src={Logo} />
          </div>
          <div className='d-flex gap-10'>
            <div className='d-none d-lg-block'><img alt='Vehicle View Advertiser' src={BrandText} className="brand-text" /></div>
            <div className='stores d-none d-sm-flex flex-column flex-xl-row align-items-center gap-10'>
              <a href="/" className="playstore"><img alt='Play store link' src={PlayStore} className="playstore" /></a>
              <a href='/' className="playstore"><img alt='App Store link' src={AppStore} className="appstore" /></a>
            </div>
          </div>
        </div>
      </section>
      <MenuBar />
      <TickerBar />
    </header>
  );
}

export default Header;
