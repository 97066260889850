import React, { useEffect, useState } from 'react';
import Carousel from '../../../components/carousel/Carousel';
import { base_url } from '../../../config/api';
import { Vehicle } from '../../../models/Vehicle';
import { ApiService } from '../../../services/ApiService';

function HomeSliderComp() {
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    ApiService('get', '/vehicles?populate=*&sort[0]=publishedAt%3Adesc')
      .then((resp) => {
        const deals = 'data' in resp ?
          resp.data.map(
            (m: Vehicle) => {
              return m;
            }
          )
          : [];
        setVehicles(deals);
      });
  }, [])

  return (
    <div className="row mb-5">
      <div className="col-sm-12">
        <div className="content-wrapper">
          <Carousel items={1} height={200} interval={4000}>

            {vehicles.map((vehicle: Vehicle) =>

              <div key={'Slider_' + vehicle.id} className='d-flex flex-column mx-2'>
                <div className="cover-img img-cover h-400" style={{ height: 500 }}>
                  <img alt={vehicle.attributes.Name} height={400} className="ticker-dealer-image" src={base_url + vehicle.attributes.Cover.data.attributes.url} />

                  <div className='overlay-info mx-2'>

                    <div className='d-flex justify-content-between'>
                      <div>
                        <div className="article-title font-bold p-4 text-uppercase align-items-center">
                          {vehicle.attributes.Name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default HomeSliderComp;
