import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from '../../components/carousel/Carousel';
import SectionTitle from '../../components/SectionTitle';
import { base_url } from '../../config/api';
import { Article } from '../../models/Article';
import { ApiService } from '../../services/ApiService';

type ArticleProps = {
  article: Article,
}
function RelatedArticlesComp({ article }: ArticleProps) {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const filters: string[] = [];
    article.attributes.categories.data.forEach(c => {
      filters.push('filters[categories][id][$in]=' + c.id)
    });
    console.log(filters.join());

    ApiService('get', '/articles?populate=*&pagination[limit]=4&sort[0]=publishedAt:DESC&' + filters.join('&') + '&filters[id][$ne]=' + article.id)
      .then((resp) => {
        const deals = 'data' in resp ?
          resp.data.map(
            (m: Article) => {
              return m;
            }
          )
          : [];
        setArticles(deals);
      });
  }, [article.attributes.categories.data, article.id])

  return (
    <div className="row mb-5">
      <div className="col-sm-12">
        <SectionTitle title={'Related Articles'} />
        <div className="content-wrapper row">
          {articles.map((article: Article) =>

            <div key={'article_' + article.id} className="col-sm-3 mb-3">

              <Link to={'/content/' + article.attributes.slug} className='d-flex flex-column bg-white h-100 shadow-sm'>
                <div className="cover-img">
                  <img alt={article.attributes.Title} height={180} className="ticker-article-image w-100" src={base_url + article.attributes.Cover.data.attributes.url} />
                </div>
                <div className='text-center p-3 text-uppercase font-bold'>{article.attributes.Title}</div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RelatedArticlesComp;
