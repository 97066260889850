import { useEffect, useState } from 'react';
import { ApiService } from '../../services/ApiService';
import { base_url } from '../../config/api';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import RelatedListingsComp from './RelatedListings';
import { Vehicle } from '../../models/Vehicle';

function SingleListingPage() {
  let { category, slug } = useParams();
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    console.log('**************************************************************');

    // ApiService('get', '/sponsors?pagination[limit]=5&populate=*')
    ApiService('get', '/vehicles?populate=*&filters[slug][$eqi]=' + slug)
      .then((resp) => {
        const allVehicles = 'data' in resp ?
          resp.data.map(
            (m: Vehicle) => {
              return m;
            }
          )
          : [];
        setVehicles(allVehicles);
      });
  }, [category, slug])

  return (
    <div className="container pb-5">
      {vehicles.map((vehicle: Vehicle) =>
        <>
          <div className="row mb-5">

            <div key={'vehicle_' + vehicle.id} className="col-sm-12 mb-4">

              <div className='d-flex flex-column bg-white h-100 shadow-sm'>
                <div className="img-cover h-500">
                  <img
                    alt={vehicle.attributes.Name}
                    height={500}
                    className="ticker-dealer-image w-100"
                    src={base_url + vehicle.attributes.Cover.data.attributes.url} />
                  <div className="categories">
                    <span className="badge">

                    </span>
                  </div>
                </div>
                <h2 className='px-5 pt-5 pb-2 font-bold'>{vehicle.attributes.Name}</h2>
                <div className='px-5 pb-3'>
                  <FontAwesomeIcon icon={faClock} />
                  <span>{vehicle.attributes.publishedAt.toString()}</span>
                </div>
                {/* <div className='px-5 pb-3'>
                  <FontAwesomeIcon icon={faLayerGroup} />
                  <span>{vehicle.attributes.categories.data.map((cat: Category) =>
                    <Link to={"/content/category/" + cat.attributes.slug} className="badge bg-primary ms-2">{cat.attributes.name}</Link>
                  )}</span>
                </div> */}

                <hr className='my-3' />

                <div className="vehicle-body px-5 pt-3 pb-5" dangerouslySetInnerHTML={{ __html: vehicle.attributes.about }} ></div>
              </div>
            </div>
          </div>

          <RelatedListingsComp vehicle={vehicle} />
        </>
      )
      }
    </div >
  );
}

export default SingleListingPage;
