import React, { useEffect, Fragment, ReactElement } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

type ScrollerProps = {
  children: ReactElement | ReactElement[];
}
function ScrollToTop({ children }: ScrollerProps) {
  const location = useLocation();
  const navType = useNavigationType();

  useEffect(() => {
    if (navType !== "POP") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location]);

  return <>{children}</>;
}

export default ScrollToTop;
