import { ReactElement } from "react";
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./Carousel.css";


type CarouselProps = {
  children: ReactElement | ReactElement[];
  interval?: number;
  items?: number
  nav?: boolean
  dots?: boolean
  dotsClass?: string
  dotClass?: string
  height?: number
};

const Carousel = ({ children, interval = 3000, items = 3, nav = false, dots = false, dotClass, dotsClass, height }: CarouselProps) => {


  return (

    <div className='container-fluid' >
      <OwlCarousel
        autoplay={true}
        autoplayTimeout={interval}
        autoplayHoverPause
        autoplaySpeed={interval}
        items={items}
        className="owl-theme"
        loop
        nav={nav}
        dots={dots}
        dotClass={dotClass}
        dotsClass={dotsClass}
        // navClass
        height={height}
        margin={8} >

        {children}

      </OwlCarousel>
    </div>
  )
}


export default Carousel;
