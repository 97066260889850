import React from 'react'
import PropTypes from 'prop-types'
import { Link, Path, To } from 'react-router-dom'

type SectionTitleProps = {
  title: String,
  link?: To,
  linkText?: String,
}
function SectionTitle({ title, link, linkText = 'See More' }: SectionTitleProps) {
  return (
    <div className={link !== undefined ? 'section-title-wrapper with-more d-flex align-items-center' : 'section-title-wrapper'}>
      <h2 className={link !== undefined ? 'section-title flex-grow-1' : 'section-title'}>{title}</h2>
      {link !== undefined ? <Link to={link} className="more-link" >
        {linkText}
      </Link>
        : <></>

      }

    </div>
  )
}


export default SectionTitle
