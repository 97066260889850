import { useEffect, useState } from 'react';
import SectionTitle from '../../../components/SectionTitle';
import { base_url } from '../../../config/api';
import { Vehicle } from '../../../models/Vehicle';
import { ApiService } from '../../../services/ApiService';

function ReviewsComp() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    ApiService('get', '/vehicles?populate=*&pagination[limit]=4&sort[0]=publishedAt%3Aasc&sort[1]=id%3Adesc')
      .then((resp) => {
        const deals = 'data' in resp ?
          resp.data.map(
            (m: Vehicle) => {
              return m;
            }
          )
          : [];
        setReviews(deals);
      });
  }, [])

  return (
    <div className="row mb-5">
      <div className="col-sm-12">
        <SectionTitle title={'Vehicle Reviews'} link='/vehicle-reviews' />
        <div className="content-wrapper row">
          {reviews.map((vehicle: Vehicle) =>

            <div key={'review_' + vehicle.id} className="col-sm-3 mb-3">

              <div className='d-flex flex-column bg-white h-100 shadow-sm'>
                <div className="img-cover h-180 position-relative">
                  <img alt={vehicle.attributes.Name} height={180} className="ticker-dealer-image w-100" src={base_url + vehicle.attributes.Cover?.data?.attributes.url} />

                </div>
                <div className='p-3 text-uppercase font-bold'>
                  <div className="article-title">
                    {vehicle.attributes.Name}
                  </div>
                  {/* <div>{vehicle.attributes.dealership?.data.attributes.Name}</div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReviewsComp;
