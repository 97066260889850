import { useEffect, useState } from 'react';
import { ApiService } from '../../services/ApiService';
import { error } from 'console';

function HomePage() {
  const [bodyContent, setBodyContent] = useState('');

  useEffect(() => {
    ApiService('get', '/privacy-policy')
    .then(
      (resp: any ) => {
        console.log(resp.data);
        setBodyContent(resp.data.attributes.body)
      },
      (error) => {}
    );
  }, []);

  return (
    <div className="container py-5">
      <div dangerouslySetInnerHTML={{ __html: bodyContent }} />
    </div>
  );
}

export default HomePage;
