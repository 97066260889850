import axios from "axios";
import { api_key, api_url } from "../config/api";

export async function ApiService(method: string, url: string, isExternal: boolean = false) {
  var config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: api_url + url,
    headers: {
      'Authorization': 'Bearer ' + api_key
    }
  };

  return await axios(config)
    .then(function (response) {
      return { status: true, data: response.data.data, meta: response.data.meta };
    })
    .catch(function (error) {
      console.log(error);
      return { status: false, error: error }
    });

}
