import { useEffect } from 'react';
import './App.css';
import Header from './layouts/header/Header';
import Footer from './layouts/footer/Footer';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import ListingsPage from './pages/listings/ListingsPage';
import ReviewsPage from './pages/reviews/ReviewsPage';
import SponsorsPage from './pages/sponsors/SponsorsPage';
import ArticlesPage from './pages/articles/ArticlesPage';
import SingleArticlePage from './pages/articles/SingleArticlePage';
import ScrollToTop from './components/ScrollToTop';
import SingleReviewPage from './pages/reviews/SingleReviewPage';
import SingleSponsorPage from './pages/sponsors/SingleSponsorPage';
import SingleListingPage from './pages/listings/SingleListingPage';
import PrivacyPolicyPage from './pages/privacy/PrivacyPolicyPage';

function App() {
  useEffect(() => {
  }, [])

  return (
    <ScrollToTop>
      <div className="App">
        <Header></Header>
        <div className="content-wrapper">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/listings" element={<ListingsPage />} />
            <Route path="/listings/:slug" element={<SingleListingPage />} />
            <Route path="/vehicle-reviews" element={<ReviewsPage />} />
            <Route path="/vehicle-reviews/:slug" element={<SingleReviewPage />} />
            <Route path="/vehicle-reviews/category/:category" element={<ReviewsPage />} />
            <Route path="/sponsors" element={<SponsorsPage />} />
            <Route path="/sponsors/:slug" element={<SingleSponsorPage />} />
            <Route path="/content/category/:category" element={<ArticlesPage />} />
            <Route path="/content/:slug" element={<SingleArticlePage />} />
          </Routes>

        </div>
        <Footer></Footer>
      </div>
    </ScrollToTop>
  );
}

export default App;
