import { faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className='footer'>
      <div className="container">
        <div className="d-flex justify-content-between align-center">
          <div className='d-flex gap-4'>
            <div>Copyright &copy; All Rights Recerved</div>
            <div><Link to={'/privacy-policy'}>Privacy Policy</Link></div>
          </div>
          <div className='social-icons d-flex gap-3'>
            <a href="facebook" className="facebook">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="twiter" className="twiter">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="Instagram" className="Instagram">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="youtube" className="youtube">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
