import React, { useEffect, useState } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { Pagination } from '../models/Paginator'

type PaginationProps = {
  total: number
}
function PaginationComp({ total = 0 }: PaginationProps) {
  const [searchParams] = useSearchParams();
  const [pages, setPages] = useState<JSX.Element[]>([])
  const location = useLocation()



  useEffect(() => {

    const page = searchParams.get('page') ?? '1';
    const pageSize = searchParams.get('pageSize') ?? '9';
    const activePage = parseInt(page);
    const totalPages = Math.ceil(total / parseInt(pageSize));
    const buttons = [];

    // Limit the number of buttons to 7
    const maxButtons = 7;
    let startPage = Math.max(activePage - Math.floor(maxButtons / 2), 1);
    let endPage = startPage + maxButtons - 1;
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxButtons + 1, 1);
    }

    // Add the previous page button if we're not on the first page
    if (activePage > 1) {
      buttons.push(
        <li className="page-item">
          <Link to={location.pathname + '?page=' + (activePage - 1)} className="page-link" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </Link>
        </li>
      );
    }

    // Add a number button for each page within the range
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i} className={i === activePage ? "page-item active" : "page-item"}>
          <Link to={location.pathname + '?page=' + i} className="page-link">{i}</Link>
        </li>
      );
    }

    // Add the next page button if we're not on the last page
    if (activePage < totalPages) {
      buttons.push(
        <li key='next' className="page-item">
          <Link to={location.pathname + '?page=' + (activePage + 1)} className="page-link" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </Link>
        </li>
      );
    }
    setPages(buttons);
  }, [location.pathname, searchParams, total])

  return (

    <div className="row mb-4">
      <div className="col-sm-12 d-flex justify-content-center">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            {pages}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default PaginationComp
