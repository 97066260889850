import { useEffect } from 'react';
import DealershipsComp from './comps/Dealerships';
import SponsorsComp from './comps/Sponsors';
import ReviewsComp from './comps/Reviews';
import HomeSliderComp from './comps/HomeSlider';

function HomePage() {

  useEffect(() => {
  }, [])

  return (
    <div className="container">
      <HomeSliderComp />
      <DealershipsComp />
      <SponsorsComp />
      <ReviewsComp />
    </div>
  );
}

export default HomePage;
