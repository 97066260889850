import { useEffect, useState } from 'react';
import { ApiService } from '../../services/ApiService';
import { base_url } from '../../config/api';
import { Article } from '../../models/Article';
import { Link, useSearchParams } from 'react-router-dom';
import { Pagination } from '../../models/Paginator';
import PaginationComp from '../../components/PaginationComp';

function ReviewsPage() {
  const [reviews, setReviews] = useState([]);
  const [pagination, setPagination] = useState<Pagination>({ pageCount: 0, total: 0, });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const page = searchParams.get('page') ?? '1';
    const pageSize = searchParams.get('pageSize') ?? '9';
    ApiService('get', '/reviews?populate=*&pagination[pageSize]=' + pageSize + '&pagination[page]=' + page)
      .then((resp) => {
        const categoryReviews = 'data' in resp ?
          resp.data.map(
            (m: Article) => {
              return m;
            }
          )
          : [];
        const meta: Pagination = 'meta' in resp ?
          {
            page: resp.meta.pagination.page,
            pageSize: resp.meta.pagination.pageSize,
            total: resp.meta.pagination.total,
            pageCount: resp.meta.pagination.pageCount
          }
          : { page: 1, pageSize: 9, total: 0, pageCount: 0 };
        setPagination(meta)
        setReviews(categoryReviews);
      });
  }, [searchParams])

  return (
    <div className="container py-5">
      <div className="row mb-5">
        {reviews.map((review: Article) =>

          <div key={'review_' + review.id} className="col-sm-4 mb-4">

            <Link to={'/vehicle-reviews/' + review.attributes.slug} className='d-flex flex-column bg-white h-100 shadow-sm'>
              <div className="cover-img h-300">
                <img
                  alt={review.attributes.Title}
                  height={300}
                  className="ticker-dealer-image w-100"
                  src={base_url + review.attributes.Cover.data.attributes.url} />
                <div className="categories">
                  <span className="badge">

                  </span>
                </div>
              </div>
              <div className='text-center p-3 text-uppercase font-bold'>{review.attributes.Title}</div>
            </Link>
          </div>
        )}
      </div>
      <PaginationComp total={pagination.total} />
    </div>
  );
}
export default ReviewsPage;
