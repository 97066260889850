import { useEffect, useState } from 'react';
import { ApiService } from '../../services/ApiService';
import { base_url } from '../../config/api';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { Sponsor } from '../../models/Sponsor';

function SingleSponsorPage() {
  let { slug } = useParams();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // ApiService('get', '/sponsors?pagination[limit]=5&populate=*')
    ApiService('get', '/sponsors?populate=*&filters[slug][$eqi]=' + slug)
      .then((resp) => {
        const categoryArticles = 'data' in resp ?
          resp.data.map(
            (m: Sponsor) => {
              return m;
            }
          )
          : [];
        setArticles(categoryArticles);
      });
  }, [slug])

  return (
    <div className="container pb-5">
      {articles.map((article: Sponsor) =>
        <>
          <div className="row mb-5">

            <div key={'article_' + article.id} className="col-sm-12 mb-4">

              <div className='d-flex flex-column bg-white h-100 shadow-sm'>
                <div className="img-cover h-500">
                  <img
                    alt={article.attributes.Name}
                    height={500}
                    className="ticker-dealer-image w-100"
                    src={base_url + article.attributes.Logo.data.attributes.url} />
                  <div className="categories">
                    <span className="badge">

                    </span>
                  </div>
                </div>
                <h2 className='px-5 pt-5 pb-2 font-bold'>{article.attributes.Name}</h2>
                <div className='px-5 pb-3'>
                  <FontAwesomeIcon icon={faClock} />
                  <span>{article.attributes.publishedAt.toString()}</span>
                </div>

                <hr className='my-3' />

                <div className="article-body px-5 pt-3 pb-5" dangerouslySetInnerHTML={{ __html: article.attributes.About }} ></div>
              </div>
            </div>
          </div>
        </>
      )
      }
    </div >
  );
}

export default SingleSponsorPage;
