import React, { useEffect, useState } from 'react';
import Carousel from '../../../components/carousel/Carousel';
import SectionTitle from '../../../components/SectionTitle';
import { base_url } from '../../../config/api';
import { Dealership } from '../../../models/Dealership';
import { ApiService } from '../../../services/ApiService';

function DealershipsComp() {
  const [dealerships, setDealerships] = useState([]);

  useEffect(() => {
    // ApiService('get', '/sponsors?pagination[limit]=5&populate=*')
    ApiService('get', '/dealerships?populate=*')
      .then((resp) => {
        const deals = 'data' in resp ?
          resp.data.map(
            (m: Dealership) => {
              console.log('xxx: ',m);
              
              return m;
            }
          )
          : [];
        setDealerships(deals);
      });
  }, [])

  return (
    <div className="row mb-5">
      <div className="col-sm-12">
        <SectionTitle title={'Our Dealerships'} />
        <div className="content-wrapper">
          <Carousel>

            {dealerships.map((dealer: Dealership) =>

              <div key={'dealer_' + dealer.id} className='d-flex flex-column mx-2'>
                <div className="cover-img img-contain h-180">
                  <img alt={dealer.attributes.Name} height={180} className="ticker-dealer-image" src={base_url + dealer.attributes.Logo.data.attributes.url} />
                </div>
                <div className='text-center p-3 text-uppercase font-bold'>{dealer.attributes.Name}</div>
              </div>
            )}

          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default DealershipsComp;
