import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function TopBar() {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const d = new Date();
  let day = days[d.getDay()];
  let month = months[d.getMonth()];
  let date = d.getDate();
  let year = d.getFullYear();

  return (
    <section className="topbar">
      <div className="container">
        <div className="topbar-content d-flex justify-content-between">
          <div className='date'>
            <FontAwesomeIcon icon={faClock} />
            <span> {day}, {month} {date}, {year}</span>
          </div>
          <div className='social-icons d-flex'>
            <a href="facebook" className="facebook">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="twiter" className="twiter">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="Instagram" className="Instagram">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="youtube" className="youtube">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopBar;
