import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from '../../components/carousel/Carousel';
import SectionTitle from '../../components/SectionTitle';
import { base_url } from '../../config/api';
import { Vehicle } from '../../models/Vehicle';
import { ApiService } from '../../services/ApiService';

type VehicleProps = {
  vehicle: Vehicle,
}
function RelatedListingsComp({ vehicle }: VehicleProps) {
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    const filters: string[] = [];
    // vehicle.attributes.categories.data.forEach(c => {
    //   filters.push('filters[categories][id][$in]=' + c.id)
    // });
    // console.log(filters.join());

    ApiService('get', '/vehicles?populate=*&pagination[limit]=4&sort[0]=publishedAt:DESC&' + filters.join('&') + '&filters[id][$ne]=' + vehicle.id)
      .then((resp) => {
        const deals = 'data' in resp ?
          resp.data.map(
            (m: Vehicle) => {
              return m;
            }
          )
          : [];
        setVehicles(deals);
      });
  }, [vehicle.id])

  return (
    <div className="row mb-5">
      <div className="col-sm-12">
        <SectionTitle title={'Related Vehicles'} />
        <div className="content-wrapper row">
          {vehicles.map((vehicle: Vehicle) =>

            <div key={'vehicle_' + vehicle.id} className="col-sm-3 mb-3">

              <Link to={'/listings/' + vehicle.attributes.slug} className='d-flex flex-column bg-white h-100 shadow-sm'>
                <div className="cover-img">
                  <img alt={vehicle.attributes.Name} height={180} className="ticker-vehicle-image w-100" src={base_url + vehicle.attributes.Cover.data.attributes.url} />
                </div>
                <div className='text-center p-3 text-uppercase font-bold'>{vehicle.attributes.Name}</div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RelatedListingsComp;
