import React, { useEffect, useState } from 'react';
import { TickerArticle } from '../../models/TickerArticle';
import { ApiService } from '../../services/ApiService';
import Carousel from '../../components/carousel/Carousel';
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { Article } from '../../models/Article';
import { base_url } from '../../config/api';
// import "./Carousel.css";



function TickerBar() {

  const [articles, setArticles] = useState<Article[]>([]);


  useEffect(() => {
    ApiService('get', '/articles?pagination[limit]=6&populate=*&sort[0]=publishedAt%3Adesc&sort[1]=id%3Adesc')
      .then((resp) => {
        const arts = 'data' in resp ?
          resp.data.map(
            (m: Article) => { return m; }
          )
          : [];
        console.log(arts);

        setArticles(arts);
      });
  }, []);

  return (
    <section className='tickerbar'>
      <div className='container'>
        <div className='d-flex'>
          <div className="ticker-title">
            <div className="title-text">
              <span className='animate'>Latest Articles</span>
            </div>
          </div>
          <div className='ticker-articles flex-grow-1 overflow-hidden'>
            <OwlCarousel
              autoplay={true}
              autoplayTimeout={5000}
              autoplayHoverPause
              autoplaySpeed={5000}
              items={3}
              className="owl-theme"
              loop
              nav={false}
              dots={false}
              margin={8} >
              {articles.map((article: Article) =>
                <Link to={'/content/' + article.attributes.slug} key={'art_' + article.id} className='d-flex'>
                  <div className="cover-img me-4">
                    <img alt={article.attributes.Title} height={50} className="ticker-article-image" src={base_url + article.attributes.Cover.data.attributes.url} />
                  </div>
                  <div>{article.attributes.Title}</div>
                </Link>
              )}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TickerBar;
